<template>
    <v-footer app color="primary" class="white--text">
        <div class="caption" v-if="checkMode">
            <v-icon class="caption" color="white">code</v-icon>
            In development!
        </div>
        <v-spacer/>
        <span class="caption">&copy; 2019</span>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    computed: {
        checkMode() {
            return (process.env.NODE_ENV === 'development')
        }
    },
}
</script>

<style scoped>

</style>